.users_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 70vw;
    margin: 30px auto 0;
}

.users_title {
    font-size: 20px;
}

.button_exit {
    background-color: rgba(255, 59, 48, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    padding: 5px 20px;
    margin: 0;
    border: none;
    border-radius: 24px;
    text-transform: uppercase;
}

.button_exit:hover {
    cursor: pointer;
}

.user_delete_button {
    background-color: rgba(255, 59, 48, 1);
    font-weight: 500;
    line-height: 1;
    color: #fff;
    border-radius: 24px;
    border: none;
    padding: 5px 10px;
}

.user_delete_button:hover {
    cursor: pointer;
}

.users {
    display: flex;
    flex-direction: column;
    margin: 100px auto 0;
    align-items: center;
}

.users_table {
    border: 1px solid #000;
    border-collapse: collapse;
    width: 70vw;
}

.users_table_head {
    text-align: center;
    padding: 10px;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
}

.users_table_item {
    text-align: center;
    padding: 5px;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
}

@media (max-width: 1280px) {
    .users_header {
        max-width: 90vw;
    }
    
    .users_table {
        width: 90vw;
    }
    
}

@media (max-width: 980px) {
    .users_header {
        max-width: 95vw;
        margin: 15px auto 0;
    }

    .users_title {
        font-size: 16px;
    }
    
    .button_exit {
        font-size: 12px;
    }
    
    .user_delete_button {
        padding: 5px 10px;
        font-size: 12px;
    }
    
    .users {
        margin: 50px auto 0;
    }
    
    .users_table {
        width: 95vw;
    }
    
    .users_table_head {
        font-size: 14px;
    }
    
    .users_table_item {
        font-size: 12px;
    }
}

@media (max-width: 760px) {
    .users_header {
        max-width: 95vw;
        margin: 10px auto 0;
    }

    .users_title {
        font-size: 14px;
    }
    
    .button_exit {
        font-size: 10px;
        margin: 0;
    }
    
    .user_delete_button {
        font-size: 8px;
    }
    
    .users {
        margin: 25px auto 0;
    }
    
    .users_table_head {
        font-size: 10px;
    }
    
    .users_table_item {
        font-size: 8px;
    }
}

@media (max-width: 550px) {
    .users_header {
        max-width: 100%;
        margin: 10px auto 0;
    }

    .users_table {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
}