.about-project {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    width: 90vw;
    max-width: 840px;
    margin: 0 auto 50px;
}

.about-project_head {
    display: flex;
    width: 100%;
    margin: 50px 0;
    align-items: center;
    justify-content: space-between;
}

.about-project__title {
    line-height: 24px;
    font-weight: 500;
    text-align: start;
    padding: 0;
    margin: 0;
    font-size: 2.4em;
    color: rgba(17, 38, 60, 1);
}

.about-project__form-button-back {
    background: url('../../images/icon-back.svg') no-repeat center/cover;
    border: none;
    margin: 0;
    padding: 0;
    width: 39px;
    height: 39px;
}

.about-project__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.about-project__paragraph {
    line-height: 24px;
    font-size: 1.6em;
    font-weight: 400;
    margin: 0;
    padding: 10px 0;
    text-align: start;
}

.about-project__paragraph b {
    font-weight: 500;
}

.about-project__paragraph_title {
    padding-top: 30px;
    padding-bottom: 20px;
    font-weight: 500;
    font-size: 1.8em;
    margin: 20px 0;
    line-height: 24px;
}

.about-project__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: start;
}

.about-project__list-item {
    margin: 0;
}

.about-project__paragraph_item-title {
    text-align: start;
    font-weight: 500;
}

.about-project__paragraph_item {
    text-align: start;
}

.about-project__img {
    width: 100%;
    max-width: 700px;
    margin: 20px auto 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

.about-project__paragraph_fund {
    margin: 50px 0 0;
    font-weight: 400;
}

.about-project__paragraph_bold {
    font-weight: 500;
}

.about-project__paragraph-link {
    font-weight: 500;
    font-size: 1.8em;
    margin: 50px 0 0;
    line-height: 24px;
}

.about-project__links {
    margin: 0 0 50px;
    list-style-type: none;
    padding: 0;
}

.about-project__link {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    gap: 10px;
}

.link-name {
    margin: 0;
}

.link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: rgba(25, 178, 230, 1);
    transition: color .2s linear;
    margin: 0;
    padding: 10px 0;
    line-height: 24px;
}

.link:hover {
    color: rgba(25, 178, 230, .7);
}

.about-project__company {
    margin: 50px auto 0;
    text-align: center;
    font-weight: 400;
    font-size: 1.4em;
    line-height: 24px;
}

@media (max-width: 840px) {

    .about-project {
        margin: 50px auto 150px;
    }

    .about-project_head {
        margin: 30px 0;
    }
    
    .about-project__title {
        font-size: 2em;
    }
    
    .about-project__paragraph {
        font-size: 1.4em;
        padding: 10px 0;
    }
    
    .about-project__paragraph_title {
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 1.6em;
        margin: 10px 0;
    }
    
    .about-project__img {
        max-width: 75vw;
        margin: 10px auto 30px;
    }
    
    .about-project__paragraph_fund {
        margin: 0;
    }
    
    .about-project__paragraph-link {
        font-size: 1.6em;
        margin: 20px 0 0;
    }
    
    .about-project__links {
        margin: 0;
    }

    .about-project__link {
        flex-direction: column;
        gap: 0;
        margin-bottom: 0;
    }
    
    .link {
        padding: 0;
        font-size: 14px;
    }
}

@media (max-width: 540px) {
    .about-project {
        margin: 25px auto 150px;
    }

    .about-project_head {
        margin: 15px 0;
    }
    
    .about-project__paragraph {
        padding: 8px 0;
    }
    
    .about-project__paragraph_title {
        padding-top: 15px;
        padding-bottom: 8px;
        font-size: 1.6em;
        margin: 8px 0;
    }
    
    .about-project__img {
        max-width: 85vw;
        margin: 10px auto 20px;
    }
    
    .about-project__paragraph-link {
        font-size: 1.6em;
        margin: 10px 0 0;
    }
    
    .about-project__links {
        margin: 0;
    }

    .about-project__link {
        flex-direction: column;
        gap: 0;
        margin-bottom: 8px;
    }
    
    .link {
        font-size: 14px;
    }

    .about-project__company {
        margin: 25px auto 0;
        font-size: 1.4em;
        line-height: 24px;
    }
}