.profile {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.profile__form {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  margin: 20px 0 0;
  max-width: 320px;
}

.profile__form-title {
  line-height: 1;
  font-weight: 500;
  text-align: center;
  padding: 0;
  font-size: 2em;
  margin: 0;
}

.profile__form-label,
.profile__form-legend,
.profile__form-text-gender {
  line-height: 1;
  /* padding: 0 0 0 16px; */
  font-size: 1.4em;
  font-weight: 400;
  margin: 15px 0 6px;
}

.profile__form-fieldset {
  display: flex;
  gap: 9px;
  padding: 3px 16px;
  border: 1px solid rgba(199, 203, 210, 1);
  border-radius: 24px;
  transition: border .5s linear;
}

.profile__form-fieldset:hover {
  border: 1px solid #19b2e6;
}

.profile__form-fieldset .profile__form-label {
  margin: 15px 10px;
  font-size: 17px;
}

.profile__form-gender {
  display: flex;
}

.profile__form-gender .profile__form-label {
  margin-left: 0;
  padding-left: 10px;
}

.profile__form-input {
  line-height: 1;
  font-weight: 400;
  margin: 0;
  border-radius: 25px;
  border: 1px solid rgba(199, 203, 210, 1);
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  color: rgba(17, 38, 60, 1);
  font-size: 1.7em;
  padding: 16px;
  transition: border .5s linear;
  outline: none;
}

.profile__form-input:hover {
  border: 1px solid #19b2e6;
}

.profile__form-input-birth {
  width: calc(100% - 34px);
}

.profile__form-phone-info-text {
  display: flex;
  justify-content: space-between;
}

.profile__form-button-show-more {
  background: url(../../images/icon-info.svg) no-repeat center / cover;
  border: none;
  height: 2.5em;
  width: 2.5em;
  padding: 0;
  opacity: 1;
  display: flex;
  align-self: center;
  transition: opacity .5s linear;
}

.profile__form-button-show-more:hover {
  cursor: pointer;
  opacity: .8;
}

.PhoneInputInput {
  background-color: rgba(255, 255, 255, 1);
  border: none;
  outline: none;
}

.profile__form-gender:hover {
  cursor: pointer;
}

.profile__form input::-moz-placeholder {
  color: rgba(49, 49, 49, .6);
}

.profile__form input::-webkit-input-placeholder {
  color: rgba(49, 49, 49, .6);
}

.profile__button,
.profile__link {
  line-height: 1;
  font-weight: 400;
  padding: 13px 0;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(25, 178, 230, 1);
  border: none;
  border-radius: 24px;
  opacity: 1;
  transition: opacity .5s linear;
  font-size: 1.7em;
  margin: 0 0 10px;
}

.profile__link {
  margin-top: 30px;
  text-decoration: none;
  text-align: center;
}

.profile__link:hover {
  opacity: .7;
}

.profile__button:hover {
  cursor: pointer;
  opacity: .7;
}

.profile__button:last-of-type {
  margin-bottom: 0;
}

.profile__button_type_active {
  background-color: darkgrey;
}

.profile__button_type_active:hover {
  opacity: 1;
  cursor: default;
}

.profile__button_type_exit {
  background-color: rgba(255, 59, 48, 1);
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked+label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: #b3d7ff;
}
/* стили для активного состояния чекбокса (при нажатии на него) */
.custom-checkbox:not(:disabled):active+label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
/* стили для чекбокса, находящегося в фокусе */
.custom-checkbox:focus+label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
.custom-checkbox:focus:not(:checked)+label::before {
  border-color: #80bdff;
}
/* стили для чекбокса, находящегося в состоянии disabled */
.custom-checkbox:disabled+label::before {
  background-color: #e9ecef;
}

@media (max-width: 400px) {
  .profile {
    margin-bottom: 100px;
  }

  .profile__form {
    max-width: 90vw;
  }

  .profile__form-gender .profile__form-label {
    margin-left: 0;
    padding-left: 0;
  }

  .profile__form-text-gender,
  .profile__form-gender .profile__form-label,
  .profile__form-input {
    font-size: 14px;
  }
}