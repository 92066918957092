.footer {
  display: none;
}

@media (max-width: 840px) {
  .footer {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    z-index: 200;
    border-radius: 32px;
  }
}