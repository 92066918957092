.popup-info {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 300;
  transition: visibility .2s linear, background-color .2s linear, opacity .2s linear;
  opacity: 0;
}

.popup-info__container {
  position: relative;
}

.popup-info_opened {
  visibility: visible;
  opacity: 1;
  background-color: rgba(0, 0, 0, .4);
}

.popup-info__show-more-button {
  background: url(../../images/icon-info.svg) no-repeat center / cover;
  border: none;
  height: 3em;
  width: 3em;
  padding: 0;
  margin: 0;
}

.popup-info__show-more-button:hover {
  cursor: pointer;
}

.popup-info__text {
  display: flex;
  width: 100%;
  max-width: 25em;
  background-color: rgba(230, 230, 230, 1);
  border-radius: .5em;
  border: .05em solid rgba(0, 0, 0, 1);
  margin: 0;
  padding: .5em;
  box-shadow: 2px 2px 7px rgb(107, 105, 105);
  text-align: center;
  z-index: 201;
  font-size: 1.8em;
}

.popup-info__close-button {
  background: url(../../images/close-icon.svg) no-repeat center/contain;
  width: 32px;
  height: 32px;
  border: none;
  position: absolute;
  top: -40px;
  right: -40px;
  opacity: 1;
  transition: opacity .5s linear;
}

.popup-info__close-button:hover {
  opacity: .6;
  cursor: pointer;
}

@media (max-width: 821px) {
  .popup-info__text {
    width: 300px;
  }

  .popup-info__close-button {
    top: -36px;
    right: 0;
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 540px) {
  .popup-info__show-more-button {
    width: 2em;
    height: 2em;
  }

  .popup-info__text {
    width: 280px;
    font-size: 1.6em;
  }
}