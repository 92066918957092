.link-button {
  line-height: 1;
  font-weight: 400;
  padding: 13px 0;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(25, 178, 230, 1);
  border: none;
  border-radius: 24px;
  opacity: 1;
  transition: opacity .5s linear;
  font-size: 1.7em;
  margin: 0 0 10px;
  text-decoration: none;
  text-align: center;
}

.link-button:hover {
  opacity: .7;
  cursor: pointer;
}