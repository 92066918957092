.statistics {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: 50px;
  width: 90vw;
  max-width: 1280px;
  margin: 0 auto;
}

.statistics__month {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 353px;
  border: 1px solid rgba(199, 199, 199, 0.4);
  border-radius: 24px;
}

.statistics__month-latest,
.statistics__month-next {
  border: none;
  background: url(../../images/icon-change-month.svg) no-repeat center / cover;
  width: 32px;
  height: 32px;
  margin: 4px;
  padding: 0;
  opacity: 1;
  transition: opacity .5s linear
}

.statistics__month-latest:hover,
.statistics__month-next:hover {
  cursor: pointer;
  opacity: .7;
}

.statistics__month-next {
  transform: rotate(180deg);
}

.statistics__month-name {
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1;
  font-size: 1.6em;
  color: rgba(17, 38, 60, 1);
}

.statistics__data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  max-width: 1280px;
  gap: 30px;
  margin: 0 auto;
}

.statistics__graphics {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
  /* font-size: 1.4em; */
  font-weight: 400;
  line-height: 1;
  flex: 1;
}

.statistics__graphic-name {
  margin: 0 0 -50px;
  padding: 0;
  font-weight: 400;
  line-height: 1;
  font-size: 1.4em;
}

@media (max-width: 991px) {
  .statistics {
    flex-direction: column;
  }

  .statistics__data {
    flex-direction: column;
  }
}

@media (max-width: 840px) {
  .statistics {
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 140px;
  }

  .statistics__month {
    margin: 15px 0 -60px;
  }

  .statistics__graphics {
    gap: 20px;
  }

  .statistics__data {
    margin: 30px auto 0;
  }
}

@media (max-width: 540px) {
  .statistics {
    gap: 5px;
    margin-top: 5px;
  }

  .statistics__month-next,
  .statistics__month-latest {
    width: 24px;
    height: 24px;
  }

  .statistics__month {
    margin: 5px 0 -5px;
  }

  .statistics__data {
    gap: 0;
    margin-top: 10px;
  }

  .statistics__graphic-name {
    font-size: 1.6em;
    margin: 0 0 -30px;
  }

  .statistics__month-name {
    font-size: 1.4em;
  }

  .statistics__graphics {
    margin-top: 12px;
    gap: 10px;
    max-width: 353px;
  }
}

@media (max-width: 390px) {
  .statistics {
    margin-top: 5px;
  }

  .statistics__graphic-name {
    margin: 0 0 -32px;
  }
}