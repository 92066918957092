.navigation {
  display: none;
  align-items: center;
}

.navigation_type_header {
  display: flex;
}

.navigation_type_footer {
  display: none;
}

.navigation__menu-links {
  display: flex;
  list-style: none;
  margin: 2.5em 0 2em;
  padding: 0;
  gap: 15px;
}

.navigation__menu-link {
  color: rgba(80, 80, 80, 1);
  font-weight: 500;
  font-size: 1.9em;
  line-height: 1;
  text-decoration: none;
  opacity: 1;
  transition: opacity .5s linear;
}

.navigation__menu-link-info {
  display: flex;
  justify-content: center;
  width: 32px !important;
  height: 32px;
}

.navigation__menu-link-info svg {
  width: 24px;
  height: 24px;
}

.navigation__menu-link:hover {
  opacity: .7;
}

.navigation__menu-link_type_active {
  color: #19b2e6;
  background-color: rgba(25, 178, 230, 1);
  width: 100%;
  max-width: 39px;
  max-height: 32px;
  border-radius: 50%;
}

.navigation__menu-link_type_active-test {
  background-color: rgba(25, 178, 230, 1);;
  max-width: 120px;
  border-radius: 32px;
  margin: 0;
}

.navigation__menu-img {
  display: none;
}

@media (max-width: 841px) {
  .navigation_type_header {
    display: none;
  }

  .navigation__menu-test {
    display: flex;
    justify-content: space-evenly;
  }

  .navigation_type_footer {
    display: flex;
    justify-content: center;
    z-index: 200;
    width: 100%;
    max-width: 353px;
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 24px;
    border-radius: 32px;
  }

  .navigation__menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 353px;
    border: 1px solid rgba(199, 199, 199, 0.4);
    border-radius: 32px;
    min-height: 64px;
    box-shadow: 0px 2px 8px 0px rgba(17, 38, 60, 0.04);
    align-items: center;
  }

  .navigation__menu-links {
    margin: 0;
    display: flex;
    width: 100%;
    gap: 0;
  }

  .navigation__menu-item:first-child {
    margin-left: 16px;
  }

  .navigation__menu-item {
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }

  .navigation__menu-item_type_test {
    /* background-color: rgba(25, 178, 230, 1); */
    background-color: #30BA48;
    width: 100%;
    max-width: 120px;
    max-height: 36px;
    border-radius: 32px;
    margin: 0 16px 0;
    padding: 0;
  }

  .navigation__menu-links_type_test {
    display: flex;
    justify-content: flex-end;
  }

  .navigation__menu-link {
    display: flex;
    align-items: center;
  }

  .navigation__menu-test {
    color: rgba(255, 255, 255, 1);
    min-height: 36px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .navigation__menu-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0 5px 0 0;
    /* padding: 6px 0 0; */
  }

  .navigation__menu-img {
    display: block;
    object-fit: contain;
    width: 32px;
    height: 32px;
    /* width: 45px;
    height: 45px; */
    /* padding-top: .6em; */
  }

  .navigation__menu-inactive path {
    fill: rgba(0, 0, 0, 1);
  }

  .navigation__menu-inactive #info-path {
  }

  .navigation__menu-inactive rect {
    fill: rgba(255, 255, 255, 1);
  }

  .navigation__menu-active-test rect {
    fill: rgba(25, 178, 230, 1);
  }

  .navigation__menu-item_type_active .navigation__menu-link-info line,
  .navigation__menu-item_type_active .navigation__menu-link-info path {
    stroke: rgba(255, 255, 255, 1) !important;
  }

  .navigation__menu-item_type_active .navigation__menu-link-info circle {
    fill: rgba(255, 255, 255, 1);
  }

}

@media (max-width: 540px) {
  .navigation__menu-link {
    font-size: 1.4em;
  }
}

@media (max-width: 360px) {
  .navigation_type_footer {
    max-width: 311px;
  }

  .navigation__menu {
    max-width: 311px;
  }
}

@media (max-width: 320px) {
  .navigation__menu-img {
    width: 26px;
    height: 26px;
  }

  .navigation__menu-link {
    font-size: 1.1em;
  }

  .navigation__menu-link_type_active {
    max-width: 26px;
    min-height: 26px;
  }

  .navigation__menu-link_type_active-test {
    min-width: 120px;
    padding: 5px 0;
  }
}