@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Medium.eot');
  src: local('Geometria Medium'), local('Geometria-Medium'),
    url('./fonts/Geometria-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Geometria-Medium.woff') format('woff'),
    url('./fonts/Geometria-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria.eot');
  src: local('Geometria'),
    url('./fonts/Geometria.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Geometria.woff') format('woff'),
    url('./fonts/Geometria.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-Bold.eot');
  src: local('Geometria Bold'), local('Geometria-Bold'),
    url('./fonts/Geometria-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Geometria-Bold.woff') format('woff'),
    url('./fonts/Geometria-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('./fonts/Geometria-ExtraBold.eot');
  src: local('Geometria ExtraBold'), local('Geometria-ExtraBold'),
    url('./fonts/Geometria-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Geometria-ExtraBold.woff') format('woff'),
    url('./fonts/Geometria-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}