.wheel-of-life {
  width: 100%;
  max-width: 353px;
  height: 30vh;
  font-size: 1.2em;
  min-height: 271px;
  border: 1px solid rgba(199, 199, 199, 0.4);
  border-radius: 24px;
}

.wheel-of-life_type_stat {
  height: 50vh;
  width: 39.0625vw;
  font-size: 1.6em;
}


@media (max-width: 1096px) {
  .wheel-of-life_type_stat {
    height: 33vh;
    width: 95vw;
  }
}

@media (max-width: 840px) {
  .wheel-of-life {
    min-height: 350px;
    font-size: 1.6em;
  }

  .wheel-of-life_type_stat {
    height: 30vh;
    width: 95vw;
  }
}

@media (max-width: 540px) {
  .wheel-of-life {
    max-width: 353px;
    min-height: 300px;
    font-size: 1em;
  }

  .wheel-of-life_type_stat {
    height: 30vh;
    min-height: 270px;
    max-height: 270px;
    max-width: 353px;
  }

  .wheel-of-life_type_stat div,
  .wheel-of-life_type_stat div div canvas {
    max-width: 353px;
  }

}

@media (max-width: 400px) {
  .wheel-of-life {
    min-height: 273px;
    max-width: 277px;
  }
}