.calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex: 1;
  margin: 0 20px 148px;
  flex-direction: column;
  gap: 10px;
}

.calendar__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 353px;
  margin-bottom: 12px;
  margin-top: 20px;
  min-height: 270px;
}

.calendar__container .react-datepicker {
  width: 100%;
  max-width: 353px;
  border-radius: 24px;
  border: 1px solid rgba(199, 199, 199, 0.4);
}

.react-datepicker__month-container {
  width: 100%;
  max-width: 353px;
  font-family: "Geometria", "Arial", sans-serif;
}

.calendar__container .react-datepicker__header {
  background-color: #fff;
  border-radius: 24px 24px 0 0;
  border-bottom: 1px solid rgba(199, 199, 199, 0.4);
}

.calendar__container .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 24px;
}

.react-datepicker__header .react-datepicker__current-month {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 32px;
}

.calendar__container .react-datepicker__day--selected {
  background-color: rgba(25, 178, 230, 1);
  border-radius: 50%;
}

.calendar__container .react-datepicker__day--selected:hover {
  background-color: rgba(25, 178, 230, 1);
  border-radius: 50%;
}

.react-datepicker__header .react-datepicker__day-name {
  /* font-size: 16px; */
  text-transform: uppercase;
  padding: 11px;
  /* margin: 10px 0 0; */

  font-size: 14px;
  margin: 0;
}

.react-datepicker__week .react-datepicker__day {
  font-size: 16px;
  padding: 11px;
  margin: 0;
  border-radius: 50%;
  line-height: 24px;
  font-weight: 400;
}

.react-datepicker__week .react-datepicker__day:hover {
  border-radius: 50%;
}

.react-datepicker .react-datepicker__navigation {
  width: 100%;
  margin: 11px;
  border-radius: 50%;
  border: 1px solid rgba(25, 178, 230, 1);
  max-width: 32px;
  min-height: 32px;
}

.calendar__container .react-datepicker__navigation-icon::before {
  border-color: rgba(25, 178, 230, 1);
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  top: 6px;
  width: 10px;
}

.react-datepicker__week .react-datepicker__day--today {
  font-weight: 500;
}

.calendar__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
}

.calendar__button {
  background-color: rgba(25, 178, 230, 1);
  border-radius: 24px;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  max-width: 353px;
  min-height: 43px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(250, 250, 250, 1);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar__note {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 353px;
  min-height: 41px;
  border: 1px solid rgba(199, 199, 199, 0.4);
  border-radius: 8px;
  margin-top: 12px;
}

.calendar__note-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgba(17, 38, 60, 1);
  margin: 0 0 0 12px;
}

.calendar__note-edit {
  background: url(../../images/icon-edit.svg) no-repeat center/cover;
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

@media (max-width: 540px) {
  .calendar {
    flex-direction: column;
    justify-content: center;
    gap: 0;
    margin-bottom: 100px;
  }

  .react-datepicker__month-container {
    max-width: 353px;
  }

  .react-datepicker__header .react-datepicker__day-name {
    font-size: 14px;
    padding: 11px;
    margin: 10px 0 0;
  }

  .react-datepicker__week .react-datepicker__day {
    font-size: 14px;
    padding: 11px;
    margin: 0;
    border-radius: 50%;
  }

  .react-datepicker .react-datepicker__navigation {
    max-width: 28px;
    max-height: 28px;
    min-height: 28px;
  }

  .calendar__button {
    margin-top: 12px;
  }
}

@media (max-width: 400px) {

  .react-datepicker__header .react-datepicker__day-name {
    padding: 5px;
  }

  .react-datepicker__week .react-datepicker__day {
    padding: 5px;
  }
}

@media (max-width: 320px) {
  .react-datepicker__header .react-datepicker__day-name {
    padding: 3px;
    font-size: 12px;
  }

  .react-datepicker__week .react-datepicker__day {
    padding: 3px;
    font-size: 12px;
  }
}