.privacy {
    width: 90vw;
    max-width: 1280px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
}

.privacy_head {
    display: flex;
    width: 100%;
    margin: 40px 0 30px;
    align-items: center;
    justify-content: space-between;
}

.privacy__title {
    width: calc(100% - 39px);
    font-size: 18px;
    margin: 0;
    padding: 0;
}

.privacy__form-button-back {
    background: url('../../images/icon-back.svg') no-repeat center/cover;
    border: none;
    margin: 0;
    padding: 0;
    width: 39px;
    height: 39px;
}

.privacy__header-title {
    font-size: 24px;
    margin: 0;
    padding: 0;
}

.privacy__text {
    font-size: 16px;
    line-height: 24px;
    margin: 30px 0 0;
    padding: 0;
}

.privacy__list,
.privacy__sublist {
    list-style-type: none;
    margin: 20px 30px 0;
    padding: 0;
}

.privacy__paragraph {
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0;
    padding: 0;
}

.privacy__table {
    margin: 20px 0 0;
    padding: 0;
}

.privacy__table,
td {
    border: 1px solid rgba(17, 38, 60, 1);
    border-collapse: collapse;
}

.table-text,
.table-title {
    padding: 16px 40px;
}

@media (max-width: 840px) {
    .privacy__paragraph {
        font-size: 16px;
        margin: 10px 0;
    }

    .privacy__list,
    .privacy__sublist {
        margin: 10px 10px 0;
    }

    .privacy__table {
        margin: 10px 0 0;
    }
    
    .table-text,
    .table-title {
        padding: 10px 10px;
    }
}

@media (max-width: 540px) {
    .privacy__paragraph {
        font-size: 14px;
    }

    .privacy_head {
        margin: 20px 0 15px;
    }

    .privacy__title {
        font-size: 16px;
    }
    
    .privacy__header-title {
        font-size: 20px;
    }
    
    .privacy__text {
        font-size: 14px;
    }
}
