.popup-logout {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 200;
  transition: visibility .1s linear, background-color .1s, linear, opacity .1s linear;
  opacity: 0;
}

.popup-logout__container {
  position: relative;
  width: 100%;
  max-width: 35em;
}

.popup-logout_opened {
  visibility: visible;
  background-color: rgba(0, 0, 0, .4);
  opacity: 1;
}

.popup-logout__content {
  display: flex;
  flex-direction: column;
  background-color: rgba(230, 230, 230, 1);
  border-radius: .5em;
  border: .05em solid rgba(0, 0, 0, 1);
  margin: 0;
  padding: .5em;
  box-shadow: 2px 2px 7px rgb(107, 105, 105);
  text-align: center;
  z-index: 201;
  font-size: 1.8em;
  gap: 10px;
}

.popup-logout__close-button {
  background: url(../../images/close-icon.svg) no-repeat center/contain;
  width: 32px;
  height: 32px;
  border: none;
  position: absolute;
  top: -40px;
  right: -40px;
  opacity: 1;
  transition: opacity .5s linear;
}

.popup-logout__text {
  font-size: 22px;
}

.popup-logout__close-button:hover {
  opacity: .6;
  cursor: pointer;
}

.popup-logout__buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 15px;
}

.popup-logout__button {
  width: 100px;
  font-weight: 500;
  font-size: 20px;
  padding: 5px 0;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  opacity: 0;
  transition: all .1s linear;
  border: 1px solid #000;
  border-radius: 5px;
}

.popup-logout__button_type_active {
  opacity: 1;
}

.popup-logout__button:hover {
  background-color: rgb(0, 140, 255);
  color: rgb(240, 248, 255);
  cursor: pointer;
}

@media (max-width: 821px) {


  .popup-logout__close-button {
    top: -36px;
    right: 0;
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 540px) {}