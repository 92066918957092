#chart-div {
  width: 100%;
  max-width: 900px;
  height: 30vh;
  border: 1px solid rgba(199, 199, 199, 0.4);
  border-radius: 24px;
}

@media (max-width: 991px) {
  #chart-div {
    width: 95vw;
  }
}

@media (max-width: 840px) {
  #chart-div {
    height: 23.3vh;
    width: 90vw;
    max-width: 700px;
    padding-top: 15px;
  }
}

@media (max-width: 540px) {
  #chart-div {
    height: 22vh;
    padding-top: 10px;
    max-width: 353px;
  }
}